<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      :max-height="600"
      :src="require('@/assets/skyline.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .9), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-8">
        <v-responsive
          class="d-flex align-center mx-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <!-- <base-subheading
            class="hidden-sm-and-down"
            weight="regular"
            title="HEDGE VISION SYSTEMS"
          />
          <span class="hidden-md-and-up">
            DATA SECURITY-AS-A-SERVICE SOLUTIONS
          </span> -->

          <base-heading
            size="text-h3"
            title="HEDGE VISION SYSTEMS"
            weight="medium"
          />

          <base-body class="text-justify white--text">
            Hedge Vision Systems (HVS) was founded with a vision to empower
            alternative asset managers through expert tech consulting,
            innovative product development, and strategic insights. With our
            expertise and tailored solutions, we empower asset managers to
            optimize their operations, streamline workflows, and unlock new
            opportunities in the dynamic world of alternate asset management.
            From cutting-edge software development to strategic consulting, we
            are your trusted partner in achieving growth and success.
          </base-body>

          <div
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'flex-column align-start'
                : 'align-center'
            "
            class="d-flex flex-wrap"
          >
            <base-btn to="/contact-us"> CONTACT US </base-btn>
            <!-- <span class="font-weight-bold ml-6 mr-4 my-4">or</span>

            <base-btn to="/login"> User Login </base-btn> -->
          </div>
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
export default {
  name: "SectionHero",

  provide: {
    theme: { isDark: true },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "70vh" : "30vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
